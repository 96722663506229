// Dependent colors
$black: #000000;

$classic-base-color: #352a45;
$classic-primary-color: #5a4d73;
$classic-secondary-color: #7b689e;
$classic-highlight-color: #ffb445;

$ui-base-color: $classic-base-color !default;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-secondary-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$darker-text-color: lighten($ui-primary-color, 20%) !default;
$dark-text-color: lighten($ui-primary-color, 12%) !default;
$secondary-text-color: lighten($ui-secondary-color, 6%) !default;
$highlight-text-color: lighten($ui-highlight-color, 10%) !default;
$action-button-color:  #ffb445; //lighten($ui-base-color, 50%);

$inverted-text-color: $black !default;
$lighter-text-color: darken($ui-base-color,6%) !default;
$light-text-color: darken($ui-primary-color, 40%) !default;
